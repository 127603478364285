<template>
	<div class="w-full">
		<!-- Actions - Filter -->
		<div class="xl:flex h-screen items-center p-20 lg:pt-0">
      <div>
      <!-- QR -->
      <div class="text-center">
          <qrcode-vue :value="`${eventLinkcode}?is_interactive=1`" :size="calculateQrCodeSize - 300" level="H" />
        </div>
      </div>
      <div class="xl:ml-20 text-center lg:text-left">
        <!-- Logo -->
        <div>
          <img :src="eventLogo" alt="Kobla" class="pr-5 max-w-250 pl-2" v-if="eventLogo && eventLogo !== '@/assets/images/logo/blank.png'" />
          <img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 max-w-250 pl-2" v-if="!eventLogo" />
        </div>

        <!-- Headline -->
        <div :style="fontSizeSettings <= 42 ? 'font-size: 4em' : customStyle" class="mt-12">
         {{ eventName }}
        </div>

        <!-- More -->
        <div :style="customStyle" class="mt-32" >
          <span>{{ $t('Join at') }}</span>
          <span class="text-bold"> app.koblatil.com</span>
          <div class=" text-bold mt-2 display-event-code">#{{ eventCode }}</div>
        </div>
      </div>
		</div>
    <div class="hidden lg:block" :style="footerQr">
    </div>
	</div>
</template>

<script>
import eventApi from '@/api/event';
import QrcodeVue from 'qrcode.vue';

export default {
  components: {
    QrcodeVue,
  },
  props: {
    question: {
      type: Object,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      event: null,
      isFetching: false,
    };
  },
  computed: {
    calculateQrCodeSize() {
      const widthScale = this.windowWidth / 100;
      const heightScale = this.windowHeight / 100;
      const scale = Math.min(widthScale, heightScale);
      return 100 * scale;
    },
    eventSettings() {
      const eventSetting = this.event && this.event.settings ? JSON.parse(this.event.settings) : null;
      return eventSetting;
    },
    textFontColor() {
      return {
        'text-white': !this.isDarkFont,
        'text-black': this.isDarkFont,
      };
    },
    isDarkFont() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_dark_color;
    },
    isHideSenderName() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_hide_participant_name;
    },
    senderNameSingleChar() {
      return this.question && this.question.sender_name ? this.question.sender_name.charAt(0) : 'A';
    },
    fontSizeSettings() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_size ? this.eventSettings.style.font_size : 28;
    },
    customStyle() {
      if (this.fontSizeSettings === 28) return `font-size: ${this.fontSizeSettings}px; line-height: 36px;`;
      if (this.fontSizeSettings === 35) return `font-size: ${this.fontSizeSettings}px; line-height: 43px;`;
      if (this.fontSizeSettings === 42) return `font-size: ${this.fontSizeSettings}px; line-height: 50px;`;
      if (this.fontSizeSettings === 48) return `font-size: ${this.fontSizeSettings}px; line-height: 54px;`;
      if (this.fontSizeSettings === 60) return `font-size: ${this.fontSizeSettings}px; line-height: 68px;`;
      return `font-size: ${this.fontSizeSettings}px; line-height: 36px;`;
    },
    eventCode() {
      return this.event && this.event.code ? this.event.code : '';
    },
    eventName() {
      return this.event && this.event.name ? this.event.name : '';
    },
    eventLinkcode() {
      return this.event && this.event.link_code ? this.event.link_code : 'https://app.koblatil.com/';
    },
    eventLogo() {
      return this.event && this.event.logo ? this.event.logo : null;
    },
    footerQr() {
      return `width: 100%; height: 100px; position: absolute; bottom: 0; background-color: ${this.event.base_color}`;
    },
  },
  methods: {
    onResizePage() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
        this.addPadding();
      };
      setTimeout(() => {
        this.addPadding();
      }, 200);
    },
    addPadding() {
      const content = this.$refs.contentHighlight;
      if (this.windowWidth > 1680) {
        content.style.paddingLeft = '10%';
        content.style.paddingRight = '10%';
      } else {
        content.style.paddingLeft = '5%';
        content.style.paddingRight = '5%';
      }
    },
    getEventBySlug(eventSlug) {
      // get event by slug using eventApi
      this.isFetching = true;
      const callback = (response) => {
        this.event = response.data;
        document.title = `QR Code - ${this.event.name}`;
        this.isFetching = false;
      };
      const errorCallback = () => {
        this.isFetching = false;
      };
      eventApi.getBySlug(eventSlug, callback, errorCallback);
    },
  },
  mounted() {
    this.onResizePage();
  },
  created() {
    const eventSlug = this.$route.params.eventSlug;
    this.getEventBySlug(eventSlug);
  },
  destroyed() {
  },
};
</script>
